import { add, getUnixTime } from 'date-fns'

const date = {
  data: () => ({
    historicalDataDateRange: [
      {
        value: '6hours',
        label: '过去六小时'
      },
      {
        value: '1day',
        label: '过去一天'
      },
      {
        value: '1week',
        label: '过去一周'
      },
      {
        value: '4weeks',
        label: '过去四周'
      }
    ],
    dateRangeInSeconds: {
      '6hours': 6*60*60,
      '1day': 24*60*60,
      '1week': 7*24*60*60,
      '4weeks': 4*7*24*60*60
    }
  }),
  computed: {
    dateMonthsOptions (){
      let o = []
      for (let m = 1; m <= 12; m++) {
        o.push({
          value: String(m),
          label: `${m}月`
        })
      }
      return o
    }
  },
  methods: {
    dateConvertDateRangeToTsRange (dateRange){
      let interval = this.dateRangeInSeconds[dateRange]
      let end = new Date()
      let start = add(end, {seconds: -interval})
      return {
        start_ts: getUnixTime(start),
        end_ts: getUnixTime(end)
      }
    }
  }
}

export default date