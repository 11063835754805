import { fromUnixTime, formatRFC3339 } from 'date-fns'

const charts = {
  data: () => ({
    dataLabelMapping: {
      Lsy: '拾易水位',
      Qsy: '拾易流量',
      Vsy: '拾易功率',
      Vsyl: '拾易理论功率',
      KK: '开关状态（水电厂平均）',
    }
  }),
  methods: {
    chartsTransformHistoricalFunc (timeLabel, dataLabel){
      return (d) => [ d[timeLabel] * 1000, (Math.round(d[dataLabel] * 100) / 100).toFixed(2) ]
    },
    chartsCarbonTransformTimeseries (data, timeLabel, dataLabels){
      let transformed = []
      dataLabels.forEach( label => {
        transformed = transformed.concat( data.map( (d) => ({ group: this.dataLabelMapping[label], date: formatRFC3339(fromUnixTime(d[timeLabel]), {fractionDigits: 3}), value: (Math.round(d[label] * 100) / 100).toFixed(2) }) ) )
      })
      return transformed
    }
  }
}

export default charts