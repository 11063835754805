const selections = {
  data: () => ({
    farmTypes: [
      {
        label: '坝体式',
        value: 'body'
      },
      {
        label: '坝后式',
        value: 'rear'
      },
      {
        label: '地下式',
        value: 'underground'
      },
      {
        label: '岸边式',
        value: 'shore'
      }
    ],
    turbineTypes: [
      {
        label: '混流式',
        value: 'mix-flow'
      },
      {
        label: '轴流式',
        value: 'propeller-type'
      },
      {
        label: '冲击式',
        value: 'impulse'
      }
    ],
    performanceAdjustments: [
      {
        label: '日调节',
        value: 'daily'
      },
      {
        label: '周调节',
        value: 'weekly'
      },
      {
        label: '季调节',
        value: 'seasonal'
      },
      {
        label: '年调节',
        value: 'yearly'
      },
      {
        label: '多年调节',
        value: 'multi-yearly'
      }
    ],
    damTypes: [
      {
        label: '混凝土重力坝',
        value: 'concrete_gravity_dam'
      },
      {
        label: '混凝土碾压重力垻',
        value: 'crushed_concrete_gravity_dam'
      },
      {
        label: '拱垻',
        value: 'arch_dam'
      },
      {
        label: '土石坝',
        value: 'stone_masonry_dam'
      },
      {
        label: '面板堆石坝',
        value: 'rockfill_dam'
      },
      {
        label: '闸坝',
        value: 'sluice_dam'
      }
    ],
    bindingAccesses: [
      {
        value: '1',
        label: '只读'
      },
      {
        value: '3',
        label: '读写'
      }
    ],
    userRoles: [
      {
        value: 'admin',
        label: '超级管理员'
      },
      {
        value: 'manager',
        label: '管理员'
      },
      {
        value: 'monitor',
        label: '平台监控'
      },
      {
        value: 'owner',
        label: '风场主'
      },
      {
        value: 'user',
        label: '普通用户'
      }
    ],
    userRolesColors: [
      {
        value: 'admin',
        label: 'purple'
      },
      {
        value: 'manager',
        label: 'magenta'
      },
      {
        value: 'monitor',
        label: 'teal'
      },
      {
        value: 'owner',
        label: 'cyan'
      },
      {
        value: 'user',
        label: 'gray'
      }
    ],
    userStatus: [
      {
        value: 'admitted',
        label: '通过'
      },
      {
        value: 'waitlisted',
        label: '未通过'
      }
    ]
  }),
  methods: {
    selectionsValueToLabel (value, selections){
      let selection = selections.find( (s) => {
        return s.value === value
      } )

      return selection.label
    }
  }
}

export default selections