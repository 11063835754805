const texts = {
  filters: {
    highlightKeyword (text, keyword){
      if (keyword === '') return text
      let highlightRegex = new RegExp(`${keyword}`, 'g')
      return text.replace(highlightRegex, `<mark>${keyword}</mark>`)
    }
  },
  methods: {
    textsConvertToBoolean (string){
      if (string === 'true') {
        return true
      }

      if (string === 'false') {
        return false
      }

      return true
    },
    textsBooleanToText (bool){
      if (bool) {
        return 'true'
      } else {
        return 'false'
      }
    }
  }
}

export default texts