<template>
  <div class="wa--farms-reliability">
    <h1 class="wind--title">
      管理月报
    </h1>
    <hr class="wind--divider wind--divider--invisible" />
    <cv-content-switcher @selected="selectContent">
      <cv-content-switcher-button
        owner-id="add-farms"
        selected>
        生成月报
      </cv-content-switcher-button>
      <!--cv-content-switcher-button
        owner-id="calc-prog">
        计算进度
      </cv-content-switcher-button-->
      <cv-content-switcher-button
        owner-id="view-farms">
        月报结果
      </cv-content-switcher-button>
    </cv-content-switcher>
    <hr class="wind--divider wind--divider--invisible wind--divider--subtle" />
    <section>
      <cv-content-switcher-content owner-id="add-farms">
        <h6 class="wind--subtitle">
          计算全部风场
          <small>（仅包含可计算风场）</small>
        </h6>
        <cv-button kind="primary" @click="calculateAll">
          开始计算
        </cv-button>
        <hr class="wind--divider wind--divider--subtle" />
        <h6 class="wind--subtitle">
          计算部分风场
          <small>（可选择四个风场）</small>
        </h6>
        <cv-multi-select
          label="请输入关键词搜索，可多选"
          title="添加风场"
          filterable
          @filter="searchFarms"
          @change="updateFarmsToCalculate"
          :options="searchedFarms">
          <template v-slot:invalid-message v-if="farmsToCalculate.length > 4">
            一次计算最多选择 4 个风场
          </template>
        </cv-multi-select>
        <hr class="wind--divider wind--divider--subtle wind--divider--invisible" />
        <div v-if="farmsToCalculateParsed.length > 0">
          <cv-structured-list selectable>
            <template v-slot:headings>
              <cv-structured-list-heading>
                名称
              </cv-structured-list-heading>
              <cv-structured-list-heading>
                地区
              </cv-structured-list-heading>
              <cv-structured-list-heading>
                标签
              </cv-structured-list-heading>
              <cv-structured-list-heading>
                数据概览
              </cv-structured-list-heading>
            </template>
            <template v-slot:items>
              <cv-structured-list-item
                v-for="farm in farmsToCalculateParsed"
                :key="farm._id"
                :name="farm.name"
                :value="farm._id"
                v-model="selectedFarm">
                <cv-structured-list-data>
                  {{ farm.name }}
                </cv-structured-list-data>
                <cv-structured-list-data>
                  {{ farm.area }}
                </cv-structured-list-data>
                <cv-structured-list-data>
                  <cv-tag
                    :kind="farm.status == 'admitted' ? 'green' : 'gray'"
                    :label="farm.status == 'admitted' ? '通过' : '未通过'"></cv-tag>
                  <cv-tag
                    :kind="farm.assessment_participation ? 'cyan' : 'gray'"
                    :label="farm.assessment_participation ? '参与' : '不参与'"></cv-tag>
                  <cv-tag
                    :kind="farm.assessment_ready ? 'green' : 'gray'"
                    :label="farm.assessment_ready ? '就绪' : '未就绪'"></cv-tag>
                </cv-structured-list-data>
                <cv-structured-list-data>
                  <span>
                    额定风速：{{ farm.rated_speed }} m/s
                  </span>
                  <span class="wind--text-divider"></span>
                  <span>
                    额定功率：{{ farm.rated_power }} kWh
                  </span>
                  <span class="wind--text-divider"></span>
                  <span>
                    K = {{ numbersHandleNullInText(farm.key_var.K) }}
                  </span>
                  <span class="wind--text-divider"></span>
                  <span>
                    B = {{ numbersHandleNullInText(farm.key_var.B) }}
                  </span>
                  <span class="wind--text-divider"></span>
                  <span>
                    孪生额定风速：{{ numbersHandleNullInText(farm.key_var.native_rated_speed) }}
                  </span>
                  <span class="wind--text-divider"></span>
                  <span>
                    孪生功率曲线：a = {{ numbersHandleNullInText(farm.key_var.native_power_coefficients.a) }}, b = {{ numbersHandleNullInText(farm.key_var.native_power_coefficients.b) }}, c = {{ numbersHandleNullInText(farm.key_var.native_power_coefficients.c) }}
                  </span>
                </cv-structured-list-data>
              </cv-structured-list-item>
            </template>
          </cv-structured-list>
          <cv-button-set>
            <cv-button
              kind="primary"
              :disabled="farmsToCalculate.length === 0 || farmsToCalculate.length > 4"
              @click="openCalculate">
              生成月报
            </cv-button>
            <cv-button
              kind="danger"
              :disabled="!selectedFarm"
              @click="removeFarmFromCalculate">
              移除风场
            </cv-button>
          </cv-button-set>
        </div>
      </cv-content-switcher-content>
      <!--cv-content-switcher-content owner-id="calc-prog">
        <cv-structured-list v-if="calculations.length > 0">
          <template v-slot:headings>
            <cv-structured-list-heading>
              计算ID
            </cv-structured-list-heading>
            <cv-structured-list-heading>
              开始时间
            </cv-structured-list-heading>
            <cv-structured-list-heading>
              进度
            </cv-structured-list-heading>
          </template>
          <template v-slot:items>
            <cv-structured-list-item
              v-for="calc in calculations"
              :key="calc.metadata.id">
              <cv-structured-list-data>
                {{ calc.metadata.id }}
              </cv-structured-list-data>
              <cv-structured-list-data>
                {{ calc.metadata.timestamp }}
              </cv-structured-list-data>
              <cv-structured-list-data>
                <ccv-meter-chart :data="[{value: (calc.metadata.progress_percentage * 100).toFixed(2)}]" :options="meterChartOptions"></ccv-meter-chart>
              </cv-structured-list-data>
            </cv-structured-list-item>
          </template>
        </cv-structured-list>
        <p class="wind--text" v-else>
          无正在进行的计算。
        </p>
      </cv-content-switcher-content-->
      <cv-content-switcher-content owner-id="view-farms">
        <h1 class="wind--title">
          月度排名
        </h1>
        <cv-dropdown>
          
        </cv-dropdown>
      </cv-content-switcher-content>
    </section>
    <wa-scope
      ref="calculateScope"
      v-slot="{ data }">
      <cv-modal
        ref="calculateModal"
        auto-hide-off
        :visible="calculateVisible"
        @modal-hidden="calculateVisible = false"
        @primary-click="calculate"
        @modal-hide-request="data.calculationStarted && !data.calculationEnded ? undefined : $refs.calculateModal.hide()"
        :primary-button-disabled="data.calculationStarted"
        has-form-content>
        <template v-slot:label>
          生成月报
        </template>
        <template v-slot:title>
          请确认风场并选择时间段
        </template>
        <template v-slot:content>
          <div class="bx--form-item">
            <legend class="bx--label">
              计算ID
            </legend>
            <span>
              {{ data.cid }}
            </span>
          </div>
          <div class="bx--form-item">
            <legend class="bx--label">
              计算进度
            </legend>
            <ccv-meter-chart style="background: transparent;" :data="[{value: (data.progress * 100).toFixed(2)}]" :options="meterChartOptions"></ccv-meter-chart>
          </div>
          <div v-if="searchedFarms.length > 0" class="bx--form-item">
            <legend class="bx--label">
              参与计算风场
            </legend>
            <ul>
              <li v-for="farm in data" :key="farm._id">
                {{ farm.name }} - {{ farm.area }}
              </li>
            </ul>
          </div>
          <legend class="bx--label">
            计算时间段
          </legend>
          <cv-dropdown 
            inline
            label="年份" 
            v-model="year"
            :disabled="data.calculationStarted">
            <cv-dropdown-item
              v-for="y in yearOptions"
              :key="y.value"
              :value="y.value">
              {{ y.label }}
            </cv-dropdown-item>
          </cv-dropdown>
          <cv-dropdown 
            inline
            label="月份" 
            v-model="month"
            :disabled="data.calculationStarted">
            <cv-dropdown-item
              v-for="m in monthsOptions"
              :key="m.value"
              :value="m.value">
              {{ m.label }}
            </cv-dropdown-item>
          </cv-dropdown>
        </template>
        <template v-slot:secondary-button>
          取消
        </template>
        <template v-slot:primary-button>
          开始计算
        </template>
      </cv-modal>
    </wa-scope>
  </div>
</template>
<script>
import { numbers } from '../mixins'
import { WaScope } from '../components'
export default {
  name: 'FarmsReliability',
  mixins: [
    numbers
  ],
  components: {
    WaScope
  },
  data: () => ({
    searchedFarms: [],
    farmsToCalculate: [],
    selectedFarm: '',
    calculateVisible: false,
    minYear: 2000,
    year: null,
    month: null,
    yearOptions: [],
    calculations: [],
    cid: '',
    progress: 0,
    monitorCalculationID: null,
    monitorIntervalID: null,
    monitorInterval: 1500,
    meterChartOptions: {
      meter: {
        title: {
          percentageIndicator: {
            enabled: false
          }
        }
      }
    }
  }),
  computed: {
    farmsToCalculateParsed (){
      return this.farmsToCalculate.map( f => JSON.parse(f) )
    },
    monthsOptions (){
      let o = []
      let now = new Date()
      for (let m = 1; m <= now.getMonth()+1; m++) {
        o.push({
          value: String(m),
          label: `${m}月`
        })
      }
      return o
    }
  },
  methods: {
    selectContent (name){
      if (name === 'calc-prog') {
        this.monitorWindProc()
      } else {
        if (this.monitorIntervalID) {
          window.clearInterval(this.monitorIntervalID)
        }
      }
    },
    searchFarms (keyword){
      if (!keyword) {
        this.searchedFarms = []
        return
      }

      this.$api.farm.search({
        keyword: keyword
      }).then( r => {
        if (r.ok) {
          this.searchedFarms = r.data.farms.map( f => {
            return {
              value: JSON.stringify(f),
              label: `${f.name}`,
              name: f._id
            }
          } )
        } else {
          this.searchedFarms = []
          this.$store.dispatch('ui/toast-notification/show', {
            title: '查询出错',
            subTitle: r.error.status,
            caption: r.error.description,
            kind: 'warning'
          })
        }
      })
    },
    updateFarmsToCalculate (farms){
      this.farmsToCalculate = farms
    },
    removeFarmFromCalculate (){
      let indexAt = this.farmsToCalculate.findIndex( farm => JSON.parse(farm)._id === this.selectedFarm )

      if (indexAt === -1) return

      this.farmsToCalculate.splice(indexAt, 1)

      this.selectedFarm = ''
    },
    calculateAll (){
      this.$api.farm.getCalculable().then( r => {
        if (r.ok) {
          let farms = r.data.map( fid => {
            return JSON.stringify({
              _id: fid
            })
          })
          this.updateFarmsToCalculate(farms)
          this.openCalculate()
        } else {
          this.$store.dispatch('ui/toast-notification/show', {
            title: '查询出错',
            subTitle: r.error.status,
            caption: r.error.description,
            kind: 'warning'
          })
        }
      })
    },
    openCalculate (){
      this.$refs.calculateScope.set(this.farmsToCalculateParsed)
      this.$refs.calculateScope.update('progress', 0)
      this.$refs.calculateScope.update('cid', '无')
      this.$refs.calculateScope.update('calculationStarted', false)
      this.$refs.calculateScope.update('calculationEnded', false)
      this.setDate()
      this.calculateVisible = true
    },
    calculate (){
      let fids = this.farmsToCalculateParsed.map( f => f._id )
      let monthString = this.month.length === 1 ? `0${this.month}` : this.month
      let datestring = `${this.year}-${monthString}`
      this.$api.reliability.calculate(fids, datestring).then( r => {
        if (r.ok) {
          this.$store.dispatch('ui/toast-notification/show', {
            title: '计算已开始',
            subTitle: r.data.cid,
            caption: '此计算为可能需要一定时间。',
            kind: 'success'
          })
          this.$refs.calculateScope.update('calculationStarted', true)
          this.monitorCalculation(r.data.cid)
        } else {
          this.$store.dispatch('ui/toast-notification/show', {
            title: '计算请求出错',
            subTitle: r.error.status,
            caption: r.error.description,
            kind: 'warning'
          })
        }
      })
    },
    setDate (){
      let now = new Date()
      this.year = String(now.getFullYear())
      this.month = String(now.getMonth() + 1)
      this.yearOptions = []
      for ( let y = this.minYear; y <= now.getFullYear(); y++ ){
        this.yearOptions.unshift({
          value: String(y),
          label: `${y}年`,
          name: String(y)
        })
      }
    },
    monitorCalculation (cid){
      this.monitorCalculationID = window.setInterval( () => {
        this.$api.calculation.progress(cid).then( r => {
          this.$refs.calculateScope.update('cid', cid)
          if (r.ok) {
            let progress = r.data.calculation.metadata.progress_percentage
            this.$refs.calculateScope.update('progress', progress)
            if (progress === 1) {
              this.$refs.calculateScope.update('calculationEnded', true)
              this.$store.dispatch('ui/toast-notification/show', {
                title: '计算完成',
                caption: cid,
                kind: 'success'
              })
              window.clearInterval(this.monitorCalculationID)
            }
          } else {
            console.log(r.error)
            window.clearInterval(this.monitorCalculationID)
          }
        })
      }, this.monitorInterval )
    }
    /*
    monitorWindProc (){

      this.monitorIntervalID = window.setInterval( () => {
        this.$api.server.getWindprocStatus().then( r => {
          if (r.ok) {
            this.calculations = Object.entries(r.data.status.active_calculations).map( c => c[1] )
            this.calculations = this.calculations.sort( (a, b) => -(a.metadata.timestamp - b.metadata.timestamp) )
          } else {
            // Clear interval once error
            this.$store.dispatch('ui/toast-notification/show', {
              title: '读取计算服务器出错',
              subTitle: r.error.status,
              caption: r.error.description,
              kind: 'warning'
            })
            window.clearInterval(this.monitorIntervalID)
          }
        })
      }, this.monitorInterval )

    }*/
  }
}
</script>