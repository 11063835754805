const numbers = {
  filters: {
    numbersToString (value){
      return String(value)
    }
  },
  methods: {
    numbersHandleNullInText (number){
      return number === null ? '无数据' : number
    },
    numbersRange (start, finish){
      let range = []
      for (let i = start; i <= finish; i++) {
        range.push(i)
      }
      return range
    }
  }
}

export default numbers